<template>
  <div class="informative__container position-fixed bg-cherry p-3 d-flex align-items-center justify-content-center">
    <MButton
      :label="informativeLabel"
      variant="link"
      icon="clipboard-list"
      :ring="false"
      size="md"
      @click="$root.$emit('showCompleteModalOnlyAddress', { showOnlyAddress: false })"
      class="btn-modal text-white"
      :disabled="!isAdminUser"
    />
  </div>
</template>

<script>
import MButton from '@/shared/components/MButton.vue';

export default {
  name: 'InformativeTopBar',
  props: {
    isNewSchool: {
      type: Boolean,
      default: false,
    },
    isAdminUser: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MButton,
  },
  computed: {
    informativeLabel() {
      return this.isNewSchool
        ? this.isAdminUser
          ? this.$t('complete-register.components.informative.new-account.admin')
          : this.$t('complete-register.components.informative.new-account.non-admin')
        : this.isAdminUser
        ? this.$t('complete-register.components.informative.old-account.admin')
        : this.$t('complete-register.components.informative.old-account.non-admin');
    },
  },
};
</script>
<style lang="scss" scoped>
.informative {
  &__container {
    height: 40px;
    z-index: 10;
    width: calc(100% - var(--sidemenu-opened-width));

    /deep/ .btn-modal {
      font-size: 16px;
      font-weight: 400;
      i {
        margin-right: 16px;
      }
      &:hover:not(:disabled) {
        text-decoration: underline;
      }
    }
    @media (max-width: $screen-md) {
      width: 100%;
      height: 80px;
    }
  }
}
</style>
