






























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import TrackingHelper from '@/shared/helpers/tracking';

import SideMenuAuxItems from './SideMenuAuxItems.vue';

const SideMenuModule = namespace('sidemenu');

@Component({
  components: {
    SideMenuAuxItems,
  },
})
export default class AppSideMenu extends Vue {
  @Prop({ required: true }) user: any;
  @SideMenuModule.State sideMenuOpened!: boolean;
  @SideMenuModule.Action toggleSideMenu: () => void;
  @SideMenuModule.Action hideSideMenu: () => void;

  get canAccessFunnelsTemplates() {
    return this.$FCL.canFunnelsTemplates() && this.$ACL.canFunnelPage();
  }

  get items() {
    return [
      {
        id: 'home',
        icon: 'home',
        label: this.$t('sidemenu.main-items.home'),
        to: { path: '/home' },
        disabled: !this.$FCL.canSparkHomepage() || !this.$ACL.canHomePage(),
      },
      {
        id: 'box-open',
        icon: 'box-open',
        label: this.$t('sidemenu.main-items.products'),
        to: { path: '/sparkmembers/products/' },
        disabled: !this.$ACL.canProductPage(),
        action: {
          tooltip: this.$t('sidemenu.main-items.new-product-tooltip'),
          to: { name: 'ProductCreator', query: { location: 'side_menu' } },
          track: () => TrackingHelper.trackProductCreationInitiated('side_menu'),
          visible: this.$ACL.canCreateProduct(),
        },
      },
      // {
      //   id: 'members-area',
      //   icon: 'user-graduate',
      //   label: this.$t('sidemenu.main-items.members-area'),
      //   to: { path: '/members-area' },
      //   disabled: this.$FCL.hasXavierDisableMembersConfigView() || !this.$ACL.canMyAccountStudent(),
      // },
      {
        id: 'users-class',
        icon: 'users-class',
        label: this.$t('sidemenu.main-items.members'),
        to: { path: '/sparkmembers/members/' },
      },
      {
        id: 'filter',
        icon: 'filter',
        label: this.$t('sidemenu.main-items.funnels'),
        to: { path: '/sparkfunnels/funnels/' },
        disabled: !this.$ACL.canFunnelPage(),
        action: {
          tooltip: this.$t('sidemenu.main-items.new-funnel-tooltip'),
          to: { name: 'FunnelCreate', query: { location: 'side_menu' } },
          track: () => TrackingHelper.trackFunnelCreationInitiated('side_menu'),
          visible: true,
        },
      },
      {
        id: 'file-spreadsheet',
        icon: 'file-spreadsheet',
        label: this.$t('sidemenu.main-items.templates'),
        to: { path: '/sparkfunnels/templates/' },
        disabled: !this.canAccessFunnelsTemplates,
      },
      {
        id: 'coins',
        icon: 'coins',
        label: this.$t('sidemenu.main-items.sales'),
        to: { path: '/sparkpay/sales/' },
        disabled: !this.$ACL.canSalesPage(),
      },
      {
        id: 'wallet',
        icon: 'wallet',
        label: this.$t('sidemenu.main-items.wallet'),
        to: { path: '/sparkpay/wallet/' },
        disabled: !this.$ACL.canWalletPage(),
      },
      {
        id: 'users-crown',
        icon: 'users-crown',
        label: this.$t('sidemenu.main-items.sparkaffiliates'),
        to: { path: '/sparkAffiliates' },
        disabled: !this.$FCL.hasSparkAffiliates() || !this.$ACL.canAffiliatesPage(),
      },
      {
        id: 'cog',
        icon: 'cog',
        label: this.$t('sidemenu.main-items.automations'),
        to: { path: '/automations' },
        disabled: !this.$FCL.canActiveAutomationHero() || !this.$ACL.canAutomaticPilotPage(),
      },
    ].filter(item => !item.disabled);
  }

  activatedRoutes(item: any) {
    return this.$route.path.includes(item.to?.path);
  }

  @Watch('$route')
  hideMenuOnMobile() {
    if (matchMedia('(max-width: 1024px)').matches && this.sideMenuOpened) {
      this.hideSideMenu();
    }
  }
}
