
















import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const BreadcrumbModule = namespace('breadcrumb');

@Component({})
export default class NavbarNotifications extends Vue {
  @BreadcrumbModule.State routes!: Array<any>;

  items: Array<any> = [];

  created() {
    this.updateBreadcrumb();
  }

  @Watch('routes')
  updateBreadcrumb() {
    const mountedBreadcrumb = this.routes.map(route => ({
      text: this.$t(`breadcrumb.${route.name}`),
      to: { name: route.name },
    }));

    this.items = [...mountedBreadcrumb];
  }
}
