
















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { uploadService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';

const AuthModule = namespace('auth');
const SideMenuModule = namespace('sidemenu');

@Component({
  components: {},
})
export default class ComponentName extends Vue {
  @Prop({ required: true }) user: any;
  @Prop({ required: true }) items: Array<any>;

  @AuthModule.State loggedUser!: any;
  @AuthModule.Action updateUser: (user: any) => void;
  @SideMenuModule.Action hidePopovers: () => void;

  isUploading: boolean = false;

  logout() {
    this.$router.push('/logout');
  }

  async changeAvatar(e: any) {
    this.isUploading = true;
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.srcElement.files[0];
    try {
      const { data } = await uploadService.getAwsKeys(file.type, this.user.schoolId);
      const cover_image_url = await uploadService.upload(data.url, data.fields, file);

      this.updateUser({ ...this.loggedUser, cover_image_url });
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('my-account.views.personal-data.toast.error'));
    } finally {
      this.isUploading = false;
    }
  }
}
