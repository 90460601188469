







































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import ConsultantTopBar from '@/components/ConsultantTopBar.vue';
import CompleteRegisterInformative from './CompleteRegister/CompleteRegisterInformative.vue';
import CompleteRegisterModal from './CompleteRegister/CompleteRegisterModal.vue';
import AppNavbar from './Navbar/Navbar.vue';
import AppSideMenu from './SideMenu/SideMenu.vue';
import ToastHelper from '@/shared/helpers/toast';
import { isValidJSON } from '@/shared/helpers/general';

const AuthModule = namespace('auth');
const PlanModule = namespace('plan');
const SchoolModule = namespace('school');
const SideMenuModule = namespace('sidemenu');

@Component({
  components: {
    ConsultantTopBar,
    AppNavbar,
    AppSideMenu,
    CompleteRegisterInformative,
    CompleteRegisterModal,
  },
})
export default class MenuHeaderLayout extends Vue {
  @AuthModule.Getter fullName!: string;
  @AuthModule.State loggedUser!: any;
  @PlanModule.State actualPlan!: any;
  @SchoolModule.State selectedSchool!: any;
  @SchoolModule.Getter forceValidation!: boolean;
  @SchoolModule.Getter isNewSchool!: boolean;
  @SideMenuModule.State sideMenuOpened!: boolean;
  @SideMenuModule.Action hidePopovers!: () => void;
  @SchoolModule.Action updateSchool!: (school: any) => Promise<any>;
  @SchoolModule.Action getSchoolValidation: () => any;

  topBarClosed: boolean = false;
  showOnlyAddress: boolean = false;
  validationLoaded: boolean = false;

  get user() {
    const plan: string = this.plan;
    return {
      actualPlan: plan.charAt(0).toUpperCase() + plan.slice(1),
      avatar: this.loggedUser.cover_image_url || require('@/assets/images/default-user.jpeg'),
      email: this.loggedUser.email,
      name: this.fullName,
      schoolName: this.selectedSchool.name,
      schoolId: this.selectedSchool.id,
    };
  }

  get displayCompleteRegisterInformative() {
    return !this.selectedSchool?.validated_info?.valid && (!this.forceValidation || this.isNewSchool || this.isInternalUser) && this.validationLoaded;
  }

  get parsedAddress() {
    const address = isValidJSON(this.selectedSchool.address) ? JSON.parse(this.selectedSchool.address) : {};
    return typeof address === 'object' ? address : {};
  }

  get plan() {
    if (typeof this.actualPlan === 'string') {
      return this.actualPlan;
    }
    return this.actualPlan?.title || 'free';
  }

  get isInternalUser() {
    return this.user.email === 'contato@edools.com';
  }

  get temporarilyClosed() {
    return false;
  }

  get forceRegisterCompletion() {
    return this.forceValidation && !this.selectedSchool?.validated_info?.valid && !this.isNewSchool && !this.isInternalUser;
  }

  get isAdminUser() {
    return this.loggedUser?.role?.name === 'Administrador Geral';
  }

  get showTopBar() {
    const user = this.selectedSchool;
    const actualPlan = this.actualPlan || false;
    const disableTopbar = document.cookie.split(';').find(ck => ck.includes('disabletopbar'));

    return (
      this.$route.name !== 'HomePage' &&
      !this.topBarClosed &&
        !disableTopbar &&
        actualPlan !== 'growth' &&
        (user?.extra_settings?.show_top_bar === 'true' || false)
    );
  }

  async beforeCreate() {
    await this.$store.dispatch('school/getSchoolValidation').then(() => {
      this.validationLoaded = true;
    });
  }

  async created() {
    this.redirectToSignUp();
    this.hidePopovers();
    this.$root.$on('showCompleteModalOnlyAddress', (data: any) => {
      this.showOnlyAddress = data.showOnlyAddress;
      this.openCompleteRegisterModal();
    });
  }

  mounted() {
    if(this.forceRegisterCompletion) {
      this.openCompleteRegisterModal();
    }
  }

  redirectToSignUp() {
    if (!this.$store.state.school?.selectedSchool?.extra_settings?.questionary_filled) {
      this.$router.push({ name: 'SignUp', params: { forceQuestionary: 'true' } });
    }
  }

  openCompleteRegisterModal() {
    this.$bvModal.show('complete-register-modal');
  }

  async completeRegister(data: any) {
    try{
      const addressObj = {
          zipcode: data.address.cep,
          street: data.address.logradouro,
          neighborhood: data.address.bairro,
          city: data.address.localidade,
          state: data.address.uf,
          street_number: data.address.numero,
          complementary: data.address.complemento,
      }

      let schoolData = {
        id: this.selectedSchool.id,
        address: JSON.stringify(addressObj),
        postal_code: data.address.cep,
        street: data.address.logradouro,
        neighborhood: data.address.bairro,
        city: data.address.localidade,
        uf: data.address.uf,
        street_number: data.address.numero,
        complementary: data.address.complemento,
      }

      if (data.documentNumber) {
        schoolData[data.documentType] = data.documentNumber;
      } else {
        const prop = this.selectedSchool.cpf ? 'cpf' : 'cnpj';
        schoolData[prop] = this.selectedSchool.cpf || this.selectedSchool.cnpj;
      }

      const response = await this.updateSchool(schoolData);

      if(!response) {
        this.getSchoolValidation();
      }

      ToastHelper.successMessage(this.$t('complete-register.toast.success'));
      this.$bvModal.hide('complete-register-modal');
    }catch(e){
      ToastHelper.dangerMessage(this.$t('complete-register.toast.error'));
    }
  }
}
