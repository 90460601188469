






































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import termModal from '@/components/TermModal.vue';
import NavbarBreadcrumb from './NavbarBreadcrumb.vue';
import NavbarNotifications from './NavbarNotifications.vue';

const SideMenuModule = namespace('sidemenu');
const PlanModule = namespace('plan');
const SchoolModule = namespace('school');

@Component({
  components: {
    NavbarBreadcrumb,
    NavbarNotifications,
    termModal,
  },
})
export default class Navbar extends Vue {
  @Prop({ required: true }) user: any;
  @Prop({ required: true }) hasInformative: boolean;

  @SideMenuModule.State sideMenuOpened!: boolean;
  @PlanModule.State actualPlan!: any;
  @SideMenuModule.Action toggleSideMenu!: () => void;
  @SchoolModule.State selectedSchool!: any;
  @PlanModule.Action refreshActualPlan!: (s: any) => void;

  created() {
    if (!this.actualPlan) {
      this.refreshActualPlan(this.selectedSchool);
    }
  }
}
