





































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { hsLoading } from '@/components';
import SideMenuProfilePopover from './SideMenuProfilePopover.vue';
import BlipchatService from '@/services/blipchat';

const SideMenuModule = namespace('sidemenu');
const PlanModule = namespace('plan');
const SchoolModule = namespace('school');

@Component({
  components: {
    hsLoading,
    SideMenuProfilePopover,
  },
})
export default class SideMenuAuxItems extends Vue {
  @Prop({ required: true }) user: any;

  @SideMenuModule.State profilePopoverOpened!: boolean;
  @SideMenuModule.State sideMenuOpened!: boolean;
  @SideMenuModule.Action hidePopovers: () => void;
  @SideMenuModule.Action toggleProfilePopover: () => void;

  @SchoolModule.State selectedSchool!: any;
  @PlanModule.State actualPlan!: any;

  profileItems: any = [];

  get canAccessPlans() {
    return this.$ACL.canMyAccountPlans();
  }

  created() {
    this.setItems();
  }

  setItems() {
    this.profileItems = [
      {
        icon: 'star',
        label: this.$t('sidemenu.profile-items.plans'),
        to: { name: 'MyAccountPlans', query: { location: 'user_menu' } },
        visible: this.$ACL.canMyAccountPlans(),
      },
      {
        icon: 'user',
        label: this.$t('sidemenu.profile-items.personal-data'),
        to: { name: 'MyAccountPersonalData' },
        visible: true
      },
      {
        icon: 'cog',
        label: this.$t('sidemenu.profile-items.my-account'),
        to: { name: 'MyAccountDashboard' },
        visible: this.$ACL.canMyAccountSettings(),
      },
    ];
  }

  activatedRoutes(item: any) {
    return this.$route.path.includes(item.to?.path);
  }

  logout() {
    this.$router.push('/logout');
  }

  openChat() {
    BlipchatService.open(this.selectedSchool, this.actualPlan);
  }

  openFaqUrl() {
    window.open('http://ajuda.herospark.com/', '_blank');
  }
}
