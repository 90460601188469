<template>
  <b-modal
    id="complete-register-modal"
    hideFooter
    size="lg"
    headerClass="pb-0"
    :hideHeader="forceRegister"
    no-close-on-backdrop
    :centered="!isAdminUser"
    :bodyClass="customClass"
  >
    <div class="d-flex flex-column justify-content-center">
      <div class="text-center mb-3">
        <h3 class="modal-title font-weight-bold mb-2">{{ modalInfo.title }}</h3>
        <p class="modal-subtitle">{{ modalInfo.subtitle }}</p>
      </div>
      <div v-if="isAdminUser" class="d-flex flex-column justify-content-center">
        <DocumentChoose v-if="!shouldntDisplayDocument" @documentChoosen="validateDocument" />

        <div v-if="!validatedInfo.address">
          <hs-group :label="'CEP'" label-for="cep" label-class="hs-credit-card-panel__input-label" class="mt-3">
            <hs-input
              id="cep"
              v-model="address.cep"
              v-on:keypress="cepKeyUp($event)"
              v-on:keyup.enter="onGetLocation"
              placeholder="00000-000"
              :nestedText="!$v.address.cep.$error ? $t('my-account.views.account-settings.inputs.cep.nested-text') : ''"
              :nestedAction="onGetLocation"
              :state="!$v.address.cep.$error ? null : false"
              @blur="$v.address.cep.$touch()"
            />
            <MButton
              :label="$t('complete-register.components.modal.btn-help')"
              variant="link"
              size="md"
              :ring="false"
              @click="whatIsCEP"
              class="btn-cep pl-0"
            />
          </hs-group>
          <hs-loading v-if="isLoading" />
          <transition name="fade" mode="out-in">
            <div v-if="displayAddressInputs" class="mb-3">
              <div class="d-flex w-100">
                <hs-group :label="'Endereço'" label-for="logradouro" class="w-75 mr-4">
                  <hs-input
                    id="logradouro"
                    v-model="address.logradouro"
                    :state="!$v.address.logradouro.$error ? null : false"
                    @blur="$v.address.logradouro.$touch()"
                  />
                </hs-group>
                <hs-group :label="'Número'" label-for="numero" class="w-25">
                  <hs-input
                    id="numero"
                    v-model="address.numero"
                    :state="!$v.address.numero.$invalid ? null : false"
                    @blur="$v.address.logradouro.$touch()"
                  />
                </hs-group>
              </div>

              <div class="d-flex">
                <hs-group :label="'Bairro'" label-for="bairro" class="w-50 mr-4">
                  <hs-input
                    id="bairro"
                    v-model="address.bairro"
                    :state="!$v.address.bairro.$error ? null : false"
                    @blur="$v.address.bairro.$touch()"
                  />
                </hs-group>
                <hs-group :label="'Cidade'" label-for="cidade" class="w-50">
                  <hs-input
                    id="cidade"
                    v-model="address.localidade"
                    :state="!$v.address.localidade.$error ? null : false"
                    @blur="$v.address.localidade.$touch()"
                  />
                </hs-group>
              </div>

              <div class="d-flex">
                <hs-group :label="'Estado'" label-for="estado" class="w-50 mr-4">
                  <hs-input
                    id="estado"
                    v-model="address.uf"
                    :state="!$v.address.uf.$error ? null : false"
                    @blur="$v.address.uf.$touch()"
                  />
                </hs-group>
                <hs-group :label="'Complemento'" label-for="complemento" class="w-50">
                  <hs-input id="complemento" v-model="address.complemento" :placeholder="'(opcional)'" />
                </hs-group>
              </div>
            </div>
          </transition>
        </div>
        <div
          class="alert-info-silver d-flex justify-content-center align-items-center py-1 px-2 mb-1 px-md-0 text-center text-md-justify"
        >
          <hs-icon variant="light" icon="info-circle" class="d-none d-md-flex mr-2" size="16" />
          <span>{{ $t('complete-register.components.modal.save-informative') }}</span>
        </div>
        <span
          v-if="forceRegister"
          class="text-center"
          v-html="$t('complete-register.components.modal.support-informative')"
        />
        <MButton
          :label="$t('complete-register.components.modal.btn-save')"
          data-testid="complete-register-btn"
          variant="primary"
          size="md"
          @click="completeRegister"
          class="btn-modal text-white focus:ring-offset-0 align-self-center mt-4"
          :disabled="disableBtn"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import MButton from '@/shared/components/MButton.vue';
import { analyticsService, cepService } from '@/services';
import { maxLength, required } from 'vuelidate/lib/validators';
import { hsLoading } from '@/components';
import DocumentChoose from '@/components/DocumentChoose.vue';
import { mask } from 'vue-the-mask';
import { isValidCEP } from '@brazilian-utils/brazilian-utils';
import ToasterHelper from '@/shared/helpers/toast';
import { mapState } from 'vuex';

export default {
  name: 'CompleteRegisterModal',
  directives: { mask },
  components: {
    MButton,
    hsLoading,
    DocumentChoose,
  },
  props: {
    showOnlyAddress: {
      type: Boolean,
      default: false,
    },
    forceRegister: {
      type: Boolean,
      default: false,
    },
    isAdminUser: {
      type: Boolean,
      default: false,
    },
    validatedInfo: {
      type: Object,
      default: () => ({}),
    },
    parsedAddress: {
      type: Object,
      default: () => ({}),
    },
    isNewSchool: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    address: {
      cep: {
        maxLength: maxLength(9),
        isValidCEP,
      },
      logradouro: {
        required,
      },
      bairro: {
        required,
      },
      uf: {
        required,
      },
      localidade: {
        required,
      },
      numero: {
        required,
      },
    },
  },
  data() {
    return {
      school: {
        address: '',
      },
      documentTypes: [{ value: 'CPF', text: 'CPF' }, { value: 'CNPJ', text: 'CNPJ' }],
      documentType: { value: 'CPF', text: 'CPF' },
      hasCompleted: false,
      isLoading: false,
      document: '',
      isValidDocument: false,
      displayAddressInputs: false,
      address: {
        cep: '',
        logradouro: '',
        bairro: '',
        localidade: '',
        numero: null,
        uf: '',
        complemento: '',
      },
    };
  },
  watch: {
    'address.cep': {
      handler() {
        if (this.address?.cep?.length == 9 && !this.validateOnlyDocument) {
          this.onGetLocation();
        }
      },
    },
    parsedAddress: {
      handler() {
        if (!this.validatedInfo?.valid) {
          this.address.cep = this.parsedAddress?.zipcode;
          this.address.logradouro = this.parsedAddress?.street;
          this.address.bairro = this.parsedAddress?.neighborhood;
          this.address.localidade = this.parsedAddress?.city;
          this.address.numero = this.parsedAddress?.street_number;
          this.address.uf = this.parsedAddress?.state;
          this.address.complemento = this.parsedAddress?.complementary;
        }
      },
    },
  },
  created() {
    if (this.validateOnlyDocument) this.$v.address.cep.$touch();
    this.$root.$on('bv::modal::hide', (_, modalId) => {
      if (modalId === 'complete-register-modal') {
        const eventTrackData = {
          school_id: this.selectedSchool?.id,
          email: this.selectedSchool?.email,
          full_name: this.loggedUser?.full_name,
          user_id: this.loggedUser?.id,
          role: this.loggedUser?.role,
          school_name: this.selectedSchool?.name,
          school_subdomain: this.selectedSchool?.subdomain,
        };

        if (this.hasCompleted) {
          analyticsService.track({
            event: 'Saved Data Form',
            props: {
              ...eventTrackData,
              address: this.address,
              document: this.document,
            },
          });
        }

        if (!this.hasCompleted && this.isNewSchool) {
          analyticsService.track({
            event: 'Open Data Form',
            props: {
              ...eventTrackData,
            },
          });
        }
      }
    });
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    ...mapState('auth', ['loggedUser']),
    customClass() {
      return this.forceRegister || !this.isAdminUser ? 'p-5' : '';
    },
    shouldntDisplayDocument() {
      return this.showOnlyAddress || this.validatedInfo?.document;
    },
    isValid() {
      return this.isValidDocument || this.shouldntDisplayDocument;
    },
    disableBtn() {
      return this.$v.address.$invalid || !this.isValid;
    },
    validateOnlyDocument() {
      return this.validatedInfo.address && !this.validatedInfo.document;
    },
    modalInfo() {
      return {
        title: this.isAdminUser
          ? this.whichNeedsToValidate('title')
          : this.$t('complete-register.components.modal.non-admin.title'),
        subtitle: this.isAdminUser
          ? this.whichNeedsToValidate('subtitle')
          : this.$t('complete-register.components.modal.non-admin.subtitle'),
      };
    },
  },
  methods: {
    whichNeedsToValidate(key) {
      return !this.validatedInfo?.document && !this.validatedInfo?.address
        ? this.$t(`complete-register.components.modal.admin.full-complete.${key}${this.newSchoolSubtitle(key)}`)
        : !this.validatedInfo?.address
        ? this.$t(`complete-register.components.modal.admin.only-address.${key}${this.newSchoolSubtitle(key)}`)
        : this.$t(`complete-register.components.modal.admin.only-document.${key}${this.newSchoolSubtitle(key)}`);
    },
    newSchoolSubtitle(key) {
      return this.isNewSchool && key === 'subtitle' ? '-new-school' : '';
    },
    setAddress(address) {
      this.displayAddressInputs = true;
      this.address = {
        logradouro: address.logradouro,
        bairro: address.bairro,
        localidade: address.localidade,
        uf: address.uf,
        cep: address.cep,
        numero: null,
        complemento: '',
      };
    },
    async onGetLocation() {
      this.school.address = '';
      this.isLoading = true;
      try {
        const address = await cepService.getLocation(this.address.cep);
        if (address.erro) {
          ToasterHelper.dangerMessage(this.$t('my-account.views.account-settings.toast.zip-code-does-not-exist'));
        } else if (address) {
          this.setAddress(address);
        }
      } catch (error) {
        ToasterHelper.dangerMessage(this.$t('my-account.views.account-settings.toast.zip-code-does-not-exist'));
      } finally {
        this.isLoading = false;
      }
    },
    completeRegister() {
      if (this.$v.address.$invalid && this.$v.address.cep.$dirty) {
        this.$v.address.$touch();
      } else {
        this.hasCompleted = true;
        this.$emit('completeRegister', {
          documentType: this.documentType.value.toLowerCase(),
          documentNumber: this.document,
          address: this.address,
        });
      }
    },
    cepKeyUp($event) {
      let keyCode = $event.key;
      if (!/^[0-9]$/.test(keyCode)) {
        $event.preventDefault();
      }
      if (this.address.cep.length === 5) {
        this.address.cep = this.address.cep.substr(0, 5) + '-';
      }
    },
    validateDocument({ is_valid, document }) {
      this.document = document;
      this.isValidDocument = is_valid;
    },
    whatIsCEP() {
      window.open('https://buscacepinter.correios.com.br/app/endereco/index.php', '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.alert-info-silver {
  background: #e3e3e3;
  color: #525252;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.3px;
  border-radius: 4px;
}
/deep/ .modal-content {
  .modal-header {
    border: none;
  }
  .modal-body {
    padding: 0 32px 32px 32px;
  }
  .btn-cep {
    &:hover {
      text-decoration: underline;
    }
  }
  .modal-title {
    font-size: 24px;
  }

  .modal-subtitle {
    font-size: 16px;
  }

  .btn-modal:disabled {
    background-color: #cfcfcf;
    color: #6f6f6f !important;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.6s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  margin-top: -50px;
}
</style>
