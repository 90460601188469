<template>
  <section
    data-testid="consultant-top-bar"
    id="ConsultantTopBar"
    class="
      consultant-top-bar
      tlw-bg-gray-900 tlw-flex-wrap tlw-justify-between tlw-flex tlw-items-center tlw-py-4 tlw-px-5
      md:tlw-pl-32
      w-100
      tlw-relative
    "
  >
    <header class="tlw-text-center lg:tlw-text-left xs:tlw-w-full lg:tlw-w-1/2">
      <p class="tlw-inline-block lg:tlw-mb-0 tlw-mb-9 lg:tlw-text-xl tlw-text-xl tlw-text-white">
        {{ $t('my-account.views.plan.consultant-panel.resumed-description') }}
      </p>
    </header>
    <main class="tlw-items-stretch tlw-justify-center tlw-block lg:tlw-flex tlw-mx-auto lg:tlw-w-1/4">
      <button
        class="
          tlw-py-3 tlw-px-5 tlw-text-white tlw-rounded tlw-bg-red-500
          hover:tlw-bg-red-600
          tlw-transition-all tlw-ease-in-out
        "
        data-testid="now-consultant"
        variant="outline-primary"
        @click="handleClick()"
      >
        {{ $t('my-account.views.plan.consultant-panel.button') }}
      </button>
    </main>
    <button data-testid="close-consultant-bar" class="tlw-bg-gray-900 tlw-text-white tlw-text-3xl" @click="closeTopBar">
      <hs-icon variant="solid" icon="times" />
    </button>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { analyticsService } from '@/services';
import ZendeskService from '@/services/zendesk';

export default {
  name: 'ConsultantTopBar',
  mounted() {
    analyticsService.track({
      event: 'contact bar showed',
      props: {},
    });
  },
  methods: {
    ...mapActions('school', ['updateSchool']),
    closeTopBar() {
      const school = this.selectedSchool;
      const expires = new Date();
      expires.setTime(expires.getTime() + 172800000);
      document.cookie = `disabletopbar${school.id}=true;expires=${expires.toUTCString()}`;
      analyticsService.track({
        event: 'contact bar closed',
        props: {},
      });
      this.$emit('closed');
    },
    handleClick() {
      analyticsService.track({
        event: 'contact bar clicked - all',
        props: {},
      });
      return this.openChatSales();
    },
    async disableTopBar() {
      const school = this.selectedSchool;
      school.extra_settings.show_top_bar = false;
      await this.updateSchool({ id: school.id, extra_settings: school.extra_settings });
    },
    openChatSales() {
      analyticsService.track({
        event: 'contact bar clicked - chat',
        props: {},
      });

      ZendeskService.addTags(['Vendas']);
      ZendeskService.openChatOnSales();

      this.disableTopBar();
    },
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    ...mapState('sidemenu', ['sideMenuOpened']),
  },
};
</script>

<style lang="scss">
:root {
  --sidemenu-width: 62px;
  --sidemenu-opened-width: 194px;
}

.consultant-top-bar {
  background-position: 2% center;
  background-repeat: no-repeat;
  background-size: auto 80%;

  @media screen and (min-width: $screen-bs-md) {
    background-image: url('~@/assets/images/rocket-bg.svg');
  }

  &--content {
    width: calc(100% - var(--sidemenu-width));

    &--sidemenu-opened {
      width: calc(100% - var(--sidemenu-opened-width));
    }
  }
}
</style>
