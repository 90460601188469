












import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const AuthModule = namespace('auth');

@Component({})
export default class NavbarNotifications extends Vue {
  @AuthModule.Getter getNumberOfTheUnreadChannels!: any;

  get getNumberOfUnreadChannelsToNavbar() {
    return this.getNumberOfTheUnreadChannels <= 9 ? this.getNumberOfTheUnreadChannels : '9+';
  }

  get canAccessMessages() {
    return this.$FCL.canSparkMembers() && this.$ACL.canMessagesPage();
  }

  navigateToMessages() {
    this.$router.push({ name: 'Messages' });
  }
}
